@import '../variables';

.cr-page {
  .paging-controls {
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    .form-group {
      margin-left: 1rem;
      width: 4.5rem;
    }
  }

  // table.table tr td:last-of-type {
  //   border-bottom: 1px solid lightgrey !important;
  // }
  // table.table tr td:first-of-type {
  //   border-bottom: 1px solid lightgrey !important;
  // }
}
