.selectableBox {
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 4px;
  text-align: center;
  user-select: none;

  .btn {
    background-color: #fff;
    color: theme-color('orange');
    font-weight: 500;
    margin-top: 1rem;
    cursor: default;
  }

  &:hover:not(#{&}__selected) {
    cursor: pointer;

    .btn {
      background-color: theme-color('orange');
      color: #fff;
      cursor: pointer;
    }
  }

  &#{&}__selected {
    background-color: theme-color('orange');
    color: white;
  }

  &__fixed {
    width: 250px;
    height: 150px;
    display: grid;
    align-items: center;
    justify-items: center;
    font-size: 20px;
  }
}