/*.nav-item {
  .nav-link {
    display: flex;
    align-items: center;
    color: black;
    padding: 0.75rem 1.5rem;

    &.active {
      color: theme-color('orange');
      background: rgba(255, 255, 255, 0.23);
      opacity: 1;
    }
  }
}*/
.btn:not(.btn-sm) {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  letter-spacing: 1.25px;
  padding: .5rem 1.5rem;
}

.sidebar .nav a {
  transition: all 150ms ease-in;
}

.card-header {
  text-transform: uppercase;
}

.badge:empty {
  display: inline-block;
}

.form-check-label {
  input {
    width: 20px;
    height: 20px;
  }
}

table.table {
  background-color: white;

  &:not(.no-custom) thead,
  &:not(.no-custom) td {
    border-bottom: 1px solid lightgrey;
  }

  &:not(.no-custom) th {
    font-weight: 500;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  &:not(.no-custom) tr td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  tr td,
  th {
    &:first-of-type,
    &:last-of-type {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      border: none;
    }
    &:first-of-type {
      & + td,
      + th {
        padding-left: 1rem;
      }
    }
  }
}

div.react-select__control {
  border: none;

  .react-select__indicators {
    > span {
      display: none;
    }
  }
}

nav {
  ul.pagination {
    > li {
      border: none;

      &:not(:first-of-type) {
        margin-left: 0.5rem;
      }

      .page-link:focus {
        box-shadow: none;
      }

      &.active .page-link {
        background-color: rgba(theme-color('primary'), 0.05);
        border: 1px solid #dee2e6;
        color: theme-color('primary');
      }
    }
  }
}
