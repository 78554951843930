@import '../variables';

.settings {
  &__link {
    display: inline-block;
    padding: 0 1rem;

    &:hover {
      text-decoration: none;
    }

    &.active {
      color: theme-color('orange');
    }
  }

  &.gateway_tables {
    table.table {
      &.table-sm th,
      &.table-sm td {
        padding: 0.5rem;
      }

      th,
      td {
        vertical-align: middle;
      }

      th:not(:first-child),
      td:not(:first-child) {
        text-align: center;
      }

      th:first-child,
      td:first-child {
        padding-left: 2rem;
      }

      th,
      tr td {
        border: 1px solid #dee2e6;
      }

      input[type='checkbox'] {
        position: initial;
        margin: 0;
      }
    }
  }
}

.gateway {
  // display: flex;
  // justify-content: center;

  .wrapper {
    max-width: min-content;
    min-width: 700px;

    table.table {
      table-layout: fixed;

      .country {
        width: 210px;
      }
      .code {
        width: 100px;
      }

      .custom-checkbox
        .custom-control-input:checked
        ~ .custom-control-label::before {
        background-color: theme-color('orange') !important;
        border-color: theme-color('orange');
      }
      .custom-checkbox
        .custom-control-input:checked:focus
        ~ .custom-control-label::before {
        box-shadow: none;
        border-color: theme-color('orange');
      }
      .custom-checkbox
        .custom-control-input:focus
        ~ .custom-control-label::before {
        box-shadow: none;
      }
      .custom-checkbox
        .custom-control-input:focus
        ~ .custom-control-label::before {
        border-color: #adb5bd;
      }
    }
  }
}

.countryCodes {
  // display: flex;
  // justify-content: center;

  .wrapper {
    max-width: 600px;
    table.table {
      table-layout: fixed;

      .country {
        width: 210px;
      }
      .code {
        width: 100px;
      }

      input[type='text'] {
        text-align: right;
        background-color: transparent;
      }
    }
  }
}
