.empty {
  &__content {
    background-color: white;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .smspilot_logo {
    margin: 2rem;
    align-content: center;
    justify-content: center;
    display: flex;
  }
}
