.preview {
  border: 1px solid #00000034;
  border-radius: 4px 4px 0px 0px;
  height: 100%;
  padding: 0.75rem;

  &__wrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    color: #000000de;
    opacity: 0.6;
  }

  &__placeholder {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 50%;
    gap: 0.5rem;
    text-align: center;
  }

  &__text {
    margin-top: 60px;
    height: 90%;
    color: white !important;

    .title {
      color: #212529;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #dcdcdc;
      padding-bottom: 1rem;

      .arrow {
        position: absolute;
        left: 0;
      }
    }

    .content {
      margin: 15px;
      height: 90%;
      overflow-y: auto;
    }

    .chat {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }

    .messages {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
    }

    .message {
      border-radius: 20px;
      padding: 8px 15px;
      margin-top: 5px;
      margin-bottom: 5px;
      display: inline-block;
      width: auto;
    }

    .yours {
      align-items: flex-start;
    }

    .yours .message {
      margin-right: 25%;
      background-color: #eee;
      position: relative;
    }

    .yours .message.last:before {
      content: '';
      position: absolute;
      z-index: 0;
      bottom: 0;
      left: -7px;
      height: 20px;
      width: 20px;
      background: #eee;
      border-bottom-right-radius: 15px;
    }
    .yours .message.last:after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: -10px;
      width: 10px;
      height: 20px;
      background: white;
      border-bottom-right-radius: 10px;
    }

    .mine {
      align-items: flex-end;
    }

    .mine .message {
      color: white;
      margin-left: 25%;
      background: #5bc236;
      background-attachment: fixed;
      position: relative;
    }

    .mine .message.last:before {
      content: '';
      position: absolute;
      z-index: 0;
      bottom: 0;
      right: -8px;
      height: 20px;
      width: 20px;
      background: #5bc236;
      background-attachment: fixed;
      border-bottom-left-radius: 15px;
    }

    .mine .message.last:after {
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: -10px;
      width: 10px;
      height: 20px;
      background: white;
      border-bottom-left-radius: 10px;
    }
  }
}
