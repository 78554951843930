.fileDropzone {
  height: 50px;
  border: 1px dashed darkgray;
  color: darkgray;
  display: grid;
  margin-bottom: 15px;

  &:hover {
    border-color: gray;
    color: gray;
    cursor: pointer;
  }

  & .fileInput {
    display: none;
  }

  & > div {
    align-self: center;
    justify-self: center;
  }
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-auto-rows: 75px;
  gap: 10px;
  width: 100%;

  &__figure {
    position: relative;
    border: 1px solid grey;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:hover::after {
      background-color: rgba(255, 255, 255, 0.4);
      cursor: pointer;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.imgViewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: hidden;

  &__content {
    max-height: calc(100vh - 20px);
    padding: 1rem;
    background-color: #fff;
    border: 1px solid grey;
    display: grid;
    gap: 10px;
    overflow-y: auto;
  }

  &__img {
    display: block;
    max-height: 80vh;
    max-width: 90vw;
    object-fit: contain;
  }
}

.imgMarker {
  justify-self: center;

  &__wrapper {
    display: grid;
  }

  &__container {
    position: relative;
    max-width: 80vh;
    margin: 10px 0;
  }

  &__img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  &__svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__btnGroup {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
  }

  &__btn {
    &--up {
      grid-row: 1;
      grid-column: span 2;
    }
    &--left {
      grid-row: 2;
    }
    &--right {
      grid-row: 2;
    }
    &--down {
      grid-row: 3;
      grid-column: span 2;
    }
  }

  &__saveBtn {
    margin-top: 10px;
  }
}
