.cr-customer-statistics {
    .statistic-header {
        margin: 0;
        display: grid;
        grid-template-columns: max-content auto max-content max-content;
        grid-template-areas: 'left center right1 right2';
        grid-column-gap: 1rem;

        span {
            font-weight: 600;

            &:first-of-type {
                grid-area: left;
            }
            
            &:nth-child(2) {
                grid-area: right1;
            }

            &:last-of-type {
                grid-area: right2;
            }
        }
    }
}