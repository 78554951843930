.custom-input {
  // Checkbox
  &.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: theme-color('orange');
    border-color: theme-color('orange');
  }
  &.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
    box-shadow: none;
    border-color: theme-color('orange');
  }
  &.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
  &.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
    border-color: #adb5bd;
  }

  // Radio
  &.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: theme-color('orange');
    border-color: theme-color('orange');
  }
  &.custom-radio .custom-control-input:checked:focus ~ .custom-control-label::before {
    box-shadow: none;
    border-color: theme-color('orange');
  }
  &.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
  &.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
    border-color: #adb5bd;
  }

  // Switch
  &.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background-color: theme-color('orange');
    border-color: theme-color('orange');
  }
  &.custom-switch .custom-control-input:checked:focus ~ .custom-control-label::before {
    box-shadow: none;
    border-color: theme-color('orange');
  }
  &.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
  &.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
    border-color: #adb5bd;
  }
}