.cr-icon {
  display: flex;
  flex-direction: row;

  &.size-tiny {
    img,svg {
      height: 16px;
      width: 16px;
    }
  }
  &.size-small {
    img,svg {
      height: 24px;
      width: 24px;
    }
  }
  &.size-medium {
    img,svg {
      height: 32px;
      width: 32px;
    }
  }
  &.size-large {
    img,svg {
      height: 48px;
      width: 48px;
    }
  }
}

tr .cr-icon {
  color: lightgray;
}
