.cr-header {
  //margin-bottom: $spacer * .5;
  border-bottom: $border-width solid $border-color;
  box-shadow: 0 1px 2px 0 rgba(10, 22, 70, 0.1);
  padding: 1rem;
  padding-right: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }

  // &__notification-popover {
  //   &.popover {
  //     width: 320px;
  //     max-width: 320px;
  //     max-height: 100vh;
  //     overflow-y: scroll;
  //     cursor: pointer;
  //   }
  // }

  .headerInfo {
    align-items: inherit;

    .user_area {
      display: flex;
      gap: 2rem;
      margin-right: 2rem;

      .flag-select__option__label {
        vertical-align: sub;
      }

      .name_base {
        display: grid;
        align-self: center;
        cursor: default;

        small {
          color: theme-color('disabled');
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    &__accountItem {
      display: grid;
      grid-auto-flow: column;
      gap: .5rem;
      align-items: center;
      text-decoration: none;
      user-select: none;
    }
  }
}
