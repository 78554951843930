.tabItem {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;

  &__wrapper {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: .5rem;
  }

  &__num {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #e8e8e8;
    display: grid;
    align-items: center;
    justify-items: center;
    font-size: 12px;
    font-weight: 500;
  }

  &__text {
    font-size: 14px;
    vertical-align: middle;
  }

  &#{&}__selected {
    border-bottom: 2px solid theme-color('orange');
  }

  &#{&}__selected &__num {
    background-color: theme-color('orange');
    color: white;
  }

  &#{&}__selected &__text {
    font-weight: 700;
  }
}