// page
.cr-page {
  //min-height: 100vhs;
  padding: 0 2rem;
  background: inherit;

  &__header {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    display: flex;
    align-items: flex-end;
    align-self: center;
    padding: 2rem 0;

    background: inherit;
    position: sticky;
    top: 77px;
    z-index: 99;
  }

  &__title {
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }
    padding-right: 0.5rem;
    margin-bottom: 0;
  }

  &__breadcrumb {
    @include media-breakpoint-down(sm) {
      align-self: flex-start;
    }
    align-self: flex-end;
    padding: 0;
    text-transform: capitalize;
    background: theme-color('light');
  }
  
  &__right {
    display: grid;
    margin-left: auto;
    grid-auto-flow: column;
    grid-column-gap: 1rem;

    button {
      text-transform: uppercase;
    }
  }

  .row {
    z-index: 1;
    // stylelint-disable-next-line
    .col,
    > [class^='col-'] {
      margin-bottom: 1rem;
    }
  }
}

.cr-header + .cr-page {
  margin-top: 77px;
}

.cr-page-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
