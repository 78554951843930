.cr-color-circle {
  display: inline-block;

  &.color-red {
    background-color: theme-color('danger');
  }
  &.color-green {
    background-color: theme-color('success');
  }

  &.size-tiny {
    height: 16px;
    width: 16px;
    border-radius: 8px;
  }
  &.size-small {
    height: 24px;
    width: 24px;
    border-radius: 12px;
  }
  &.size-medium {
    height: 32px;
    width: 32px;
    border-radius: 16px;
  }
  &.size-large {
    height: 48px;
    width: 48px;
    border-radius: 24px;
  }
}

tr .cr-icon {
  color: lightgray;
}
