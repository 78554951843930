.form-group.combined-style {
  background-color: #f8f9fa;
  border-bottom: 1px solid gray;

  label {
    padding: 0 0.75rem;
    font-size: small;
    margin: 0;
    border: none;
    color: gray;
  }

  input,
  select,
  textarea {
    background: none;
    padding: 0 0.75rem;
    color: black;
    border: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  input,
  select {
    margin-top: -0.5rem;
  }

  textarea {
    padding: 0.75rem;
  }

  > p {
    font-size: medium;
    margin: 0 0 0.25rem 0;
  }
}

.form-group {
  .input-group {
    &__error {
      color: red;
      padding: 0 0.75rem;
    }
  }
}

.custom-text-input {
  margin-bottom: 1.5rem;

  .input-group {
    position: relative;

    .eye {
      color: grey;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      user-select: none;

      .row.form-group & {
        right: 0.75rem;
      }
    }
  }
}

.combined-input-with-formtext {
  margin-bottom: 1rem;
}
