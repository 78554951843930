.cr-button {
  color: white;
  background-color: theme-color('orange');

  &.size-tiny {
    height: 16px;
  }

  &.size-small {
    height: 24px;
  }

  &.size-medium {
    height: 32px;
  }

  &.size-large {
    height: 48px;
  }
}
