.customerEdit {
  display: flex;
  // justify-content: center;

  &__title {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    align-items: center;
  }

  .wrapper {
    max-width: min-content;
    min-width: 750px;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background-color: theme-color('orange') !important;
    border-color: theme-color('orange');
  }
  .custom-switch
    .custom-control-input:checked:focus
    ~ .custom-control-label::before {
    box-shadow: none;
    border-color: theme-color('orange');
  }
  .custom-switch .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }
  .custom-switch .custom-control-input:focus ~ .custom-control-label::before {
    border-color: #adb5bd;
  }
}
