.shop {

  &__view {
    width: 75%;
    margin: 0 auto;
  }

  // Payment view
  &__payment {
    display: grid;
    align-items: center;
    justify-items: center;
    margin: 0 auto 3rem;
    
    &-wrapper {
      display: grid;
      grid-auto-flow: column;
      gap: 2rem;
    }
  }

  .form-row > .col {
    margin-bottom: 0;
  }
  // Payment view end

  // Summary view
  &__summary {
    width: 45%;
    min-width: 320px;
    display: grid;

    .custom-checkbox {
      margin: 1rem auto;
    }
  }

  &__line {
    padding: .5rem;
    display: grid;
    grid-auto-flow: column;
    border-bottom: 1px solid lightgrey;

    &:first-child {
      border-top: 1px solid lightgrey;
    }

    &:last-child {
      padding: 1rem .5rem;
    }

    & :last-child {
      text-align: right;
    }
  }
  // Summary view end

  &__btn {
    margin: 4rem auto 0;
  }

  .tabBar {
    width: 50%;
    margin: 0 auto 4rem;
  }
}