.cr-content {
  // @include media-breakpoint-up(lg) {
  //   padding: 0 0 0 224px;
  // }

  // @include media-breakpoint-down(md) {
  //   &::before {
  //     content:"";
  //     display: block;
  //     height: 100%;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     background-color: rgba(255, 255, 255, .8);
  //     z-index: 1;
  //   }
  // }

  // @include media-breakpoint-down(md) {
  //   padding: 0 0 0 68px;
  // }

  // @include media-breakpoint-down(xs) {
  //   padding: 0;
  // }

  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  background: inherit;
}

.authform_logo {
  width: 100%;
  max-width: 256px;
}

.signature {
  border: 1px solid lightgray;
  width: 100%;
  height: 100%;
}

.button-link {
  background: none;
  border: none;
  margin-top: 5px;
  margin-left: 15px;

  &:hover {
    color: theme-color('btnPrimary');
    text-decoration: underline;
  }

  &_delete {
    background: none;
    border: none;
    margin-left: 15px;
    &:hover {
      color: theme-color('danger');
      text-decoration: underline;
    }
  }
}

.approval-page {
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 25px;
}

@media only screen and (max-width: 575px) {
  .approval-page {
    height: 100vh;
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .card-body {
    padding: 0.75rem;
  }
}

.no-padding {
  padding: 0;
}

.margin-5px {
  margin: 5px;
}

.margin-10px {
  margin: 10px;
}

.margin-top-5px {
  margin-top: 5px;
}

.margin-top-10px {
  margin-top: 10px;
}

.margin-left-10px {
  margin-left: 10px;
}

.margin-right-10px {
  margin-right: 10px;
}

.padding-left-10px {
  margin-left: 10px;
}

.width-100-perc {
  width: 100%;
}

.unsupported-container {
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;

  a {
    color: blue !important;
    margin-left: 5px;
    text-decoration: underline;
    color: theme-color('primary');
  }
}

.centerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerBlock {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.boldText {
  font-weight: bold;
}

.italicText {
  font-style: italic;
}

.thinBorder {
  border: thin solid;
  border-color: rgba(109, 152, 231, 0.678);
  padding: 5px;

  table {
    margin-bottom: 0;
  }
}

.splashImage {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  .splashText {
    max-width: 525px;
  }

  .footer_wrapper {
    display: flex;
    justify-content: center;
    bottom: 0;
    position: absolute;
    padding-bottom: 2rem;
    width: 100%;

    .footer {
      display: flex;
      vertical-align: middle;
      align-items: center;
      justify-content: space-between;
      width: 50%;

      .flag-select {
        .flag-select__btn {
          .flag-select__option__label {
            vertical-align: sub;
          }
        }
        .flag-select__options {
          top: -90px !important;
        }
      }
    }
  }
}

.login_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .max-width {
    max-width: 100%;
  }

  .footer {
    display: flex;
    bottom: 0;
    //position: absolute;
    padding-bottom: 2rem;
    align-items: center;

    .items {
      height: 40px;

      .link-item {
        gap: 3px;
        vertical-align: sub;
      }
    }
  }
}

.flex-justify-content-center {
  display: flex;
  justify-content: center !important;
}

.max-width-50 {
  max-width: 50%;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.text-underline {
  text-decoration: underline;
}
