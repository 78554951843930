// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
$theme-colors: (
  'primary': #000000,
  'secondary': #466969,
  'success': #45b649,
  'danger': #f85032,
  'warning': #ffd700,
  'orange': #ed6b1d,
  'info': #00c9ff,
  'btnPrimary': #45b649,
  'btnSecondary': #285a91,
  'btnCheckbox': #d8d8d8,
  'bgPrimary': #ffffff,
  'indigo': #536dfe,
  'bgSecondary': #f8f9fa,
  'disabled': #d8d8d8,
);

$enable-gradients: false;

// card
//
$card-cap-bg: #fff;

// sidebar
$cr-sidebar-width: 224px;
